<template>
    <div>
        <b-alert variant="danger" :show="errors.length > 0" dismissible>
            <div v-for="( error, errno ) in errors">
                <template v-if="errors.length > 1">{{ errno + 1 }})&nbsp;</template>
                {{ error }}
            </div>
        </b-alert>

        <b-card no-body class="mb-1 text-center">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <div class="pull-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>

                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>

                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </div>
            </b-card-header>

            <b-collapse id="filters">
                <b-card-body>
                    <b-row>
                        <b-col md="3">
                            <b-form-group description="Цех">
                                <storage-picker v-model="filters.storage" :nullable=true :allowedOnly=true>
                                </storage-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Смена">
                                <shift-input v-model="filters.shift"></shift-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Дата (от)">
                                <input type="date" class="form-control" v-model="filters.dateFrom" title=''/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Дата (до)">
                                <input type="date" class="form-control" v-model="filters.dateTo" title=''/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Статус">
                                <b-form-select v-model="filters.status" class="mb-3">
                                    <option :value="null">Все</option>
                                    <option :value="startedStatus">Начатые</option>
                                    <option :value="finishedStatus">Завершенные</option>
                                    <option :value="notStartedStatus">Не начатые</option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Закрытие смены">
                                <b-form-select v-model="filters.shiftClosing" class="mb-3">
                                    <option :value="null">Все</option>
                                    <option :value="closedShiftAct">Закрытые</option>
                                    <option :value="openedShiftAct">Открытые</option>
                                    <option :value="emptyShiftAct">Отсутствует</option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Передача">
                                <b-form-select v-model="filters.transfer" class="mb-3">
                                    <option :value="null">Все</option>
                                    <option :value="transmitted">Переданые</option>
                                    <option :value="notTransmitted">Не переданные</option>
                                    <option :value="notRequired">Не требуется</option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Показать просроченные">
                                <b-form-checkbox
                                    v-model="filters.expired"
                                    switch
                                    size="lg"
                                >
                                    {{ filters.expired ? ' Показать' : ' Не показывать' }}
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>

        <div v-if="stats">
            <b-card-group deck>
                <b-card bg-variant="success" text-variant="white" class="text-center">
                    <h4>Всего:</h4>
                    <h3>{{ stats.number_of_tasks }}</h3>
                </b-card>

                <b-card bg-variant="danger" text-variant="white" class="text-center">
                    <h4>Осталось:</h4>
                    <h3>{{ stats.number_of_remains_tasks }}</h3>
                </b-card>

                <b-card bg-variant="warning" text-variant="dark" class="text-center">
                    <h4>Не передано:</h4>
                    <h3>{{ stats.number_of_not_transmitted_tasks }}</h3>
                </b-card>
            </b-card-group>
        </div>


        <b-row v-if="items.length > 0" style="margin-top:10px;">
            <b-col md="12">
                <b-table
                    :items="preparedItems"
                    :fields="fields"
                    @row-dblclicked="towardsProductionAct"
                    tbody-tr-class="cursor-pointer"
                    responsive
                    hover
                    head-variant="dark"
                >
                    <template #cell(status)="data">
                        <span>
                            <template v-if="data.item.status === startedStatus && data.item.first_production_act">
                                Начато в {{ formatTime(data.item.first_production_act.start_at) }}
                            </template>
                            <template
                                v-else-if="data.item.status === finishedStatus && data.item.first_production_act"
                            >
                                Завершено в {{ formatTime(data.item.first_production_act.end_at) }}
                            </template>
                            <template v-else-if="data.item.status === notStartedStatus">Не начато</template>
                        </span>
                    </template>

                    <template #cell(transfer)="data">
                        <template v-if="data.item.transfer === transmitted">Передано</template>
                        <template v-else-if="data.item.transfer === notRequired">Не требуется</template>
                        <template v-else-if="data.item.transfer === notTransmitted">Не передано</template>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from 'moment';

import ShiftInput from "@components/_common/ShiftInput";
import StoragePicker from '@components/_common/StoragePicker';

import lsfMixin from '@utils/localStorageFilterMixin';

import {LARAVEL_PREFIX} from '@utils/endpoints';

const FORMAT_DATE = 'YYYY-MM-DD';

const FILTERS_PATTERN = {
    shift: null,
    status: null,
    storage: null,
    expired: true,
    transfer: null,
    shiftClosing: null,
    dateTo: moment().format(FORMAT_DATE),
    dateFrom: moment().format(FORMAT_DATE),
};

const STARTED_STATUS = 'started';
const FINISHED_STATUS = 'finished';
const NOT_STARTED_STATUS = 'not_started';

const EMPTY_SHIFT_ACT = 'empty';
const CLOSED_SHIFT_ACT = 'closed';
const OPENED_SHIFT_ACT = 'opened';

const TRANSMITTED = 'transmitted';
const NOT_REQUIRED = 'not_required';
const NOT_TRANSMITTED = 'not_transmitted';

export default {
    name: 'ProductionTasksReportDashboard',
    mixins: [lsfMixin],
    components: {StoragePicker, ShiftInput},
    data() {
        return {
            stats: null,
            errors: [],
            items: [],
            fields: [
                {key: 'storage', label: 'Цех'},
                {key: 'shift', label: 'Смена'},
                {key: 'start_at', label: 'Дата начала'},
                {key: 'end_at', label: 'Дата окончания'},
                {key: 'nomenclature', label: 'Задание'},
                {key: 'status', label: 'Статус'},
                {key: 'transfer', label: 'Передача'},
            ]
        };
    },
    computed: {
        startedStatus() {
            return STARTED_STATUS;
        },
        finishedStatus() {
            return FINISHED_STATUS;
        },
        notStartedStatus() {
            return NOT_STARTED_STATUS;
        },

        closedShiftAct() {
            return CLOSED_SHIFT_ACT;
        },
        openedShiftAct() {
            return OPENED_SHIFT_ACT;
        },
        emptyShiftAct() {
            return EMPTY_SHIFT_ACT;
        },

        transmitted() {
            return TRANSMITTED;
        },
        notRequired() {
            return NOT_REQUIRED;
        },
        notTransmitted() {
            return NOT_TRANSMITTED;
        },

        preparedItems() {
            let expired = [];
            let finished = [];
            let notStarted = [];
            let notTransmitted = [];

            this.items.forEach((item) => {
                switch (item.status) {
                    case STARTED_STATUS:
                        if (item.expired) {
                            if (this.filters.expired) {
                                expired.push(this.createItemRow(item, 'danger'));
                            }
                            break;
                        }

                        if (!item.transfer) {
                            notTransmitted.push(this.createItemRow(item, 'warning'));
                            break;
                        }

                        notStarted.push(this.createItemRow(item));
                        break;
                    case FINISHED_STATUS:
                        if (item.expired && !item.transfer) {
                            if (this.filters.expired) {
                                expired.push(this.createItemRow(item, 'danger'));
                            }
                            break;
                        }

                        if (!item.transfer) {
                            notTransmitted.push(this.createItemRow(item, 'warning'));
                            break;
                        }

                        finished.push(this.createItemRow(item, 'success'));
                        break;
                    default:
                        if (item.expired) {
                            if (this.filters.expired) {
                                expired.push(this.createItemRow(item, 'danger'));
                            }
                            break;
                        }

                        notStarted.push(this.createItemRow(item));
                        break;
                }
            });

            return [
                ...expired,
                ...notStarted,
                ...notTransmitted,
                ...finished,
            ];
        },
        preparedConditions() {
            let conditions = {};

            conditions['appends'] = 1;

            for (let key in this.getFilters) {
                if (this.getFilters[key] !== null) {
                    switch (key) {
                        case 'status':
                        case 'dateTo':
                        case 'dateFrom':
                        case 'transfer':
                        case 'shiftClosing':
                            conditions['filters[' + key + ']'] = this.getFilters[key];
                            break;
                        case 'shift':
                        case 'storage':
                            conditions['filters[' + key + ']'] = this.getFilters[key].id;
                            break;
                    }
                }
            }

            return conditions;
        }
    },
    watch: {
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value);
            }
        }
    },
    methods: {
        toRusDateTimeString(date) {
            return date ? moment(date).format('DD.MM.YYYY HH:MM') : null;
        },
        formatTime(date) {
            return date ? moment(date).format('HH:MM') : 'ДАТА НЕ УКАЗАНА';
        },

        towardsProductionAct(record) {
            if (record.first_production_act) {
                this.$router.push({name: 'ProductionActUpdate', params: {id: record.first_production_act.id}});
                return;
            }

            this.$bvModal.msgBoxConfirm(
                'Начать выпуск?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }
            ).then((value) => {
                if (value) {
                    this.$http
                        .post(LARAVEL_PREFIX + 'production_tasks/' + record.id + '/production_acts/new')
                        .then(response => {
                            let productionAct = response.data.data;

                            if (productionAct !== null) {
                                this.$router.push({
                                    name: 'ProductionActUpdate',
                                    params: {id: productionAct.id}
                                });
                            }
                        }, error => this.prepareError(error));
                }
            });
        },

        clearData() {
            this.items = [];
            this.errors = [];
            this.stats = null;
        },
        applyFilters() {
            this.clearData();

            let params = this.preparedConditions;

            this.$http
                .get(LARAVEL_PREFIX + 'reports/production-tasks', {params: params})
                .then((response) => {
                    this.items = response.data;
                })
                .catch(error => this.prepareError(error));

            if (!this.stats) {
                this.$http.get(LARAVEL_PREFIX + 'production_tasks/stats', {params: params})
                    .then(response => this.stats = response.data.data)
                    .catch(error => this.prepareError(error));
            }
        },

        createItemRow(item, rowVariant = null) {
            let shift = item.shift.name;
            if (item.shift_blocked !== null) {
                if (item.shift_blocked === true) {
                    shift += ' (закрытая)';
                } else if (item.shift_blocked === false) {
                    shift += ' (открытая)';
                }
            } else {
                shift += ' (нет акта)';
            }

            let firstProductionAct = item.production_act ?? null;

            return {
                id: item.id,
                shift: shift,
                end_at: this.toRusDateTimeString(item.production_end_at),
                start_at: this.toRusDateTimeString(item.production_start_at),
                status: item.status,
                transfer: item.transfer,
                storage: item.storage?.name,
                nomenclature: !!item.nomenclature ? item.nomenclature.name : '---',

                first_production_act: firstProductionAct,

                _rowVariant: rowVariant
            };
        },

        prepareError(error) {
            this.$root.responseError(error)
        }
    },
    created() {
        this.initFilters(this, FILTERS_PATTERN);
    },
}
</script>

<style>
.cursor-pointer {
    cursor: pointer;
}
</style>
